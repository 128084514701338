
import Vue from "vue";
import Alertize from "../../../../components/Alertize.vue";
import { initModifier } from "../../../../utils/initData";
import Overview from "./../overview.vue";
import { mapActions } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

const FIELDS_OVERRIDE = ["lat_long_list", "app_bundle_list", "deal_id_list", "placement_list", "domain_list"];

export default Vue.extend({
	name: "ModifierEdit",
	props: {},
	components: {
		Alertize,
		Overview
	},
	data: () => ({
		entity: initModifier(),
		resources: {
			advertiserList: [],
			modifierTypeList: [],
			module: [],
			modifiersOptions: {},
			deliveryModifiersType: [],
		}
	}),
	async created() {
		await this.handleShow(this.getID);
		this.entity = this.handleParsedData();
	},
	async mounted() {

		this.$nextTick(async () => {
			await this.onLoadResources();
		});

	},
	computed: {
		/* getId() {
				let pathArray = this.$route.path.split("/");
				const lastItem = last(pathArray);
				this.id = !isNaN(lastItem) ? lastItem : NaN;
			}, */

		getID(): Number {
			return Number(this.$route.params.id);
		},

		getAdvertisers(): any[] {
			return this.resources.advertiserList;
		},

		getModifierTypes(): any[] {
			return this.resources.modifierTypeList;
		},

		getModules(): any[] {
			return this.resources.module;
		},

		getDeliveryModifiers(){
			return this.resources.deliveryModifiersType;
		},

		getEntity() {
			return this.$store.state.modifier.modifier;
		},

		getParsedEntity() {
			return this.entity;
		}
	},
	methods: {

		...mapActions("loading", ["setLoadingData"]),
		...mapActions("modifier", ["getModifiersOptions", "getDeliveryModifiersType"]),

		async onLoadResources() {
			this.setLoadingData(TypeLoading.loading)
			await this.dispatchAdvertisers().then((response) => {
				this.resources.advertiserList = response;
			}).catch(Err =>{
				this.resources.advertiserList = [];
			});
			await this.dispatchModifierTypes().then((response) => {
				this.resources.modifierTypeList = response;
			}).catch(Err =>{
				this.resources.modifierTypeList = [];
			});
			await this.dispatchModules().then((response) => {
				this.resources.module = response;
			}).catch(Err =>{
				this.resources.module = [];
			});
			await this.getDeliveryModifiersType().then((response) => {
				this.resources.deliveryModifiersType = response;
			}).catch(Err =>{
				this.resources.deliveryModifiersType = [];
			});
			this.resources.modifiersOptions = await this.getModifiersOptions();
			await this.setLoadingData();
		},

		redirectTo() {
			this.setNotification({ title: "", message: "", type: "" });
			this.$router.push({ name: "ModifiersIndex" });
		},

		setNotification(notification: Notification) {
			return this.$store.dispatch(
				"proccess/setNotification",
				notification
			);
		},

		async handleUpdate(parameter: { entity: any; isBidModifier: Boolean, isDeliveryModifier: Boolean }) {
			try {
				this.setLoadingData(TypeLoading.loading)
				await this.dispatchUpdateEntity(
					parameter.entity,
					parameter.isBidModifier,
					parameter.isDeliveryModifier
				);
			} catch (error) {
				console.error("handleCreate", { error: error });
			}
			await this.setLoadingData();
		},

		handleCancel() {
			this.$router.push({ name: "ModifiersIndex" });
		},

		async dispatchAdvertisers() {
			return this.$store.dispatch("advertiser/list", {
				filters: { active: true },
				options: { sort: "name", order: "asc" }
			});
		},

		async dispatchModifierTypes() {
			return this.$store.dispatch("modifier/getModifierTypes", {});
		},

		async dispatchModules() {
			return this.$store.dispatch("modifier/getModulesList", {});
		},

		async dispatchUpdateEntity(
			entity: any,
			isBidModifier: Boolean = false,
			isDeliveryModifier: Boolean = false
		) {
			return this.$store.dispatch("modifier/updateModifier", {
				modifier: entity,
				isBidModifier,
				isDeliveryModifier
			});
		},

		async handleShow(id: Number) {
			try {
				this.setLoadingData(TypeLoading.loading)
				const result = await this.$store.dispatch("modifier/showModifier", id);

				if(result) {
					this.entity = result
				}

				await this.setLoadingData();
			} catch (error) {
				console.error("handleShow", { error: error });
				await this.setLoadingData();
			}
		},

		isOverride(key: any){
			return FIELDS_OVERRIDE.some(o => o == key);
		},

		handleParsedData() {
			let tempData = this.getEntity;
			let entity = initModifier();
			entity.id = tempData ? tempData.id : undefined;
			entity.external_id = tempData ? tempData.external_id : undefined;
			entity.bid_model_id = tempData ? tempData.bid_model_id : undefined;
			entity.name = tempData ? tempData.name : undefined;
			entity.advertiser_id = tempData
				? tempData.advertiser_id
				: undefined;
			entity.alternative_id = tempData
				? tempData.alternative_id
				: undefined;
			entity.modifier_type_id = tempData
				? tempData.modifier_type_id
				: undefined; //TODO Remover valor por default una vez se implemente Delivery Modifier
			entity.module_id = tempData ? tempData.module_id : undefined;
			entity.default_weight = tempData
				? tempData.default_weight
				: undefined;
			entity.active = tempData ? tempData.active : true;
			entity.terms = tempData && tempData.bid_terms
				? tempData.bid_terms.map(t => {
						return {
							actions: undefined,
							key: t.key,
							matching_type_id: t.matching_type_id,
							matching_type_name: undefined,
							modifier: t.modifier,
							module_id: t.module_id,
							module_name: undefined,
							value: t.value,
							value_name: t.value_name,
							override_multiplier: this.isOverride(t.key) ? t.override_multiplier : undefined
						};
				  })
				: [];

			entity.delivery_terms = tempData && tempData.delivery_terms ? tempData.delivery_terms : []
			// @ts-nocheck
			entity.fallback_weight = tempData.fallback_weight
			return entity;
		},

		async handleDeleteTerms(){
			this.entity.delivery_terms = [];
		}
	}
});
